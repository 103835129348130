/* Overlay for the modal */
.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* The modal container */
.custom-modal {
  position: relative;
  background-color: transparent; /* Transparent background for the modal */
  border-radius: 5px;
  width: 85%;
  max-width: 800px;
  overflow: hidden;
}

/* Close button styling */
.custom-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 26px;
  cursor: pointer;
  z-index: 1000;
}

/* Video styling */
.custom-modal-video {
  width: 100%;
  height: auto;
  border-radius: 5px;
  outline: none;
}
